<template>
	<div style="padding-top: 50px;">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm"
			hide-required-asterisk="" style="width:500px;">
			<el-form-item :label="$t('exchangeGift.giftName')" prop="giftName">
				<el-input v-model="ruleForm.giftName"></el-input>
			</el-form-item>
			<el-form-item :label="$t('exchangeGift.giftType')" prop="giftModel">
				<el-input v-model="ruleForm.giftModel"></el-input>
			</el-form-item>
			<el-form-item :label="$t('exchangeGift.defaultAddress')">
				<address-com @change="post(true)" :address="address" v-if="address.id" />
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')" style="width:100%;">
					{{ $t('login.submit') }}
				</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import AddressCom from "@/components/Address";

	import {
		defaultAddress,
		getUserInfo
	} from "@/api/User";
	import {
		getToken,
		save,
		get,
		changemodile,
		changeAddress
	} from "@/utils/auth";


	export default {
		components: {

			AddressCom

		},
		data() {
			return {

				ruleForm: {
					giftName: '',
					giftType: '',

				},
				rules: {
					giftName: [{
							required: true,
							message: this.$t("exchangeGift.mesGiftName"),
							trigger: 'blur'
						},

					],
					giftModel: [{
							required: true,
							message: this.$t("exchangeGift.mesGiftType"),
							trigger: 'blur'
						},

					],

				},
				address: {}
			}
		},
		created() {
			this.getAddress()
			
			
			
		},
		methods: {
			submitForm(formName) {
				
				
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$req.post("/client/im/user/gift/apply/add", {
							
							giftModel:this.ruleForm.giftName,
							giftName:this.ruleForm.giftModel,
							 phone:this.address.phone,
						     realname:this.address.realname,
							 address:changeAddress(this.address).address,
						}).then(async res => {
							this.$message({
								message: "提交成功",
								type: "success",
								onClose: (res) => {
									this.$router.push({
										path: "/index"
									}, () => {
										location.reload()
									});
								},
							});
                                
						})
					} else {
						
						console.log();
						return false;
					}
				});
			},

			//获取默认地址
			getAddress() {
				return new Promise((resolve, reject) => {
					getUserInfo().then(res => {
						var data = changemodile(res.phone);
						res.address.phone = data.phone;
						res.address.countryvalue = data.code;
						this.address = res.address;
						resolve(res)
					}).catch(err => {

					})
				})

			},
		}
	}
</script>
<style scoped>



</style>
